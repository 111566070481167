import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Col, Form } from 'react-bootstrap';
import axios from 'axios';

const Servers = () => {
  // Initialisiere den State mit dem gespeicherten Wert oder Standardwert 'true'
  const [openInNewTab, setOpenInNewTab] = useState(() => {
    const savedPreference = localStorage.getItem('openInNewTab');
    return savedPreference ? JSON.parse(savedPreference) : true; // Default ist 'true'
  });

  const [data, setData] = useState([]);
  const isDebugging = process.env.NODE_ENV === 'development';
  const BackendAddress = isDebugging ? 'http://localhost:3001' : 'https://backendtest.dein-game-server.de';
  const Pteroadress = isDebugging ? 'https://pteropanel.dein-mc-server.de' : 'https://pteropanel.dein-mc-server.de';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const res = await axios.get(`${BackendAddress}/api/server/servers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(res.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  // Funktion, um den Zustand zu wechseln und im localStorage zu speichern
  const handleSwitchChange = () => {
    const newValue = !openInNewTab;
    setOpenInNewTab(newValue);
    localStorage.setItem('openInNewTab', JSON.stringify(newValue)); // Speichert den neuen Wert im localStorage
  };

  const sendtoWebseite = (identifier) => {
    const target = openInNewTab ? '_blank' : '_self'; // Kontrolle über das Öffnen im neuen oder im gleichen Tab
    window.open(`${Pteroadress}/server/${identifier}`, target);
  };

  return (
    <div>
      <Container className="py-5">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1 className="text-center mb-5">Meine Server</h1>
          <div className="d-flex align-items-center ms-auto">
          <div className="d-flex align-items-center ms-auto">
          <Form.Label htmlFor="openInNewTabSwitch" className="mb-0 me-2">
              {openInNewTab ? 'Öffnet Verwaltungsseite im neuen Tab' : 'Öffnet Verwaltungsseite im gleichen Tab'}
            </Form.Label>
            <Form.Check 
              type="switch"
              id="openInNewTabSwitch"
              checked={openInNewTab}
              onChange={handleSwitchChange} // Switch ändert und speichert den Zustand
              className="ms-2" // Margin links hinzufügen
              style={{ display: 'inline-flex', alignItems: 'center' }} // Inline-Flex für das Layout
            />
          </div>
          </div>
        </div>
        <Row>
          {data.data && data.data.map(server => (
            <Col sm={12} md={6} lg={4} key={server.attributes.internal_id} className="mb-4">
              <Card 
                as="a" 
                onClick={() => sendtoWebseite(server.attributes.identifier)} 
                className="h-100 shadow-sm text-decoration-none"
                style={{ cursor: 'pointer' }}
              >
                <Card.Body className="d-flex flex-column">
                  <Card.Title className="mb-3 text-primary">{server.attributes.name}</Card.Title>
                  <Card.Text className="flex-grow-1">
                    Empfohlen für eine große Welt mit vielen Freunden, Plugins und einigen Mods
                  </Card.Text>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">Arbeitsspeicher: {server.attributes.limits.memory} MB</li>
                    <li className="list-group-item">Speicher: {server.attributes.limits.disk} GB</li>
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Container className="py-3">
        <h3 className="text-center">
          Zum Anmelden und Verwalten verwende initial deine Anmeldedaten von dieser Webseite. 
          Wenn du dein Passwort hier änderst, wird dies automatisch beim Server-Verwalten geändert.
        </h3>
      </Container>
    </div>
  );
};

export default Servers;
