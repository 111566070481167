import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Container } from 'react-bootstrap';

const Transaktionen = () => {
    const [balance, setBalance] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [error, setError] = useState('');
    const [oneTimeAmount, setOneTimeAmount] = useState('');
    const isDebugging = process.env.NODE_ENV === 'development';
    const BackendAddress = isDebugging ? 'http://localhost:3001' : 'https://backendtest.dein-game-server.de';


console.log(BackendAddress);

    useEffect(() => {
        const fetchTransactions = async () => {
            const token = localStorage.getItem('token');

            try {
                const response = await axios.get(`${BackendAddress}/api/balance/all-transactions`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setTransactions(response.data);
            } catch (err) {
                setError('Fehler beim Abrufen der Transaktionen.');
            }
        };

        const balance = async () => {
            const token = localStorage.getItem('token');

            try {
                const response = await axios.get(`${BackendAddress}/api/balance/balance`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setBalance(response.data);
            } catch (err) {
                setError('Fehler beim Abrufen der Transaktionen.');
            }
        };
        
        balance();
        fetchTransactions();
    }, []);

    const handleOneTimePayment = () => {
        // Holen Sie das JWT aus dem lokalen Speicher oder einem anderen sicheren Speicherort
        const token = localStorage.getItem('token');

        axios.post(`${BackendAddress}/api/bezahlen/zahlung-erstellen`, 
            { amount: oneTimeAmount },
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                window.open(response.data.url, '_blank');
            })
            .catch(error => {
                console.error('Error creating one-time payment:', error);
            });
    };

    return (
        <Container>
            <h1>Kontostand und Transaktionen</h1>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            
            <h2>Kontostand: {balance} €</h2>

            <div>
                <h2>Guthaben aufladen: </h2>
                <input
                    type="number"
                    value={oneTimeAmount}
                    onChange={e => setOneTimeAmount(e.target.value)}
                    placeholder="Aufladebereich eingeben"
                />
                <button onClick={handleOneTimePayment}>Pay Now</button>
            </div>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Betrag</th>
                        <th>Begründung</th>
                        <th>Datum</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions.map(transaction => (
                        <tr key={transaction.id}>
                            <td>{transaction.id}</td>
                            <td>{transaction.amount}</td>
                            <td>{transaction.reason}</td>
                            <td>{new Date(transaction.created_at).toLocaleString()}</td>
                            <td>{transaction.is_completed ? 'Abgeschlossen' : 'Ausstehend'}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
};

export default Transaktionen;
