import React, { useState } from 'react';
import { Container, Card, Form, Table } from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { useNavigate } from 'react-router-dom';

const Preise = () => {
  const [ram, setRam] = useState(4);
  const [storage, setStorage] = useState(100);
  const [cpu, setCpu] = useState(1);
  const navigate = useNavigate();

  const totalCost = ram + (storage / 25) + cpu;

  const handlePackageSelect = (packageName) => {
    navigate('/erstellen', { state: { packageName } });
  };

  return (
    <Container>
      <h1 className="fw-bold my-4">Preise:</h1>
      
      <h2 className="text-start">Standard Pakete:</h2>
      <div className="container">
        <div className="row">
          {/* Bronze Paket */}
          <div className="col-sm-4">
            <Card 
              as="a" 
              onClick={() => handlePackageSelect('Bronze')} 
              className="text-decoration-none" 
              style={{ cursor: 'pointer' }}  // Setzt den Mauszeiger zu einer Hand
            >
              <Card.Img variant="top" src="..." alt="Bronze Paket" />
              <Card.Body>
                <Card.Title>Bronze</Card.Title>
                <p>5€ / Monat</p>
                <Card.Text>Empfohlen für eine Hand voll Freunde mit wenigen Plugins und ohne Mods</Card.Text>
                <ul className="list-group">
                  <li className="list-group-item">1 GB Ram</li>
                  <li className="list-group-item">20 GB Speicher</li>
                  <li className="list-group-item">Intel CPU</li>
                </ul>
              </Card.Body>
            </Card>
          </div>
          {/* Silber Paket */}
          <div className="col-sm-4">
            <Card 
              as="a" 
              onClick={() => handlePackageSelect('Silber')} 
              className="text-decoration-none" 
              style={{ cursor: 'pointer' }}  // Setzt den Mauszeiger zu einer Hand
            >
              <Card.Img variant="top" src="..." alt="Silber Paket" />
              <Card.Body>
                <Card.Title>Silber</Card.Title>
                <p>5€ / Monat</p>
                <Card.Text>Empfohlen für eine große Welt mit einigen Freunden und kleinen Plugins und Mods</Card.Text>
                <ul className="list-group">
                  <li className="list-group-item">2 GB Ram</li>
                  <li className="list-group-item">50 GB Speicher</li>
                  <li className="list-group-item">Intel CPU</li>
                </ul>
              </Card.Body>
            </Card>
          </div>
          {/* Gold Paket */}
          <div className="col-sm-4">
            <Card 
              as="a" 
              onClick={() => handlePackageSelect('Gold')} 
              className="text-decoration-none" 
              style={{ cursor: 'pointer' }}  // Setzt den Mauszeiger zu einer Hand
            >
              <Card.Img variant="top" src="..." alt="Gold Paket" />
              <Card.Body>
                <Card.Title>Gold</Card.Title>
                <p>5€ / Monat</p>
                <Card.Text>Empfohlen für eine große Welt mit vielen Freunden und einigen Plugins und Mods</Card.Text>
                <ul className="list-group">
                  <li className="list-group-item">3 GB Ram</li>
                  <li className="list-group-item">75 GB Speicher</li>
                  <li className="list-group-item">Intel CPU</li>
                </ul>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      <h2 className="text-start mt-5">Advanced Pakete:</h2>
      <div className="container">
        <div className="row">
          {/* Advanced Bronze Paket */}
          <div className="col-sm-4">
            <Card 
              as="a" 
              onClick={() => handlePackageSelect('Advanced Bronze')} 
              className="text-decoration-none" 
              style={{ cursor: 'pointer' }}  // Setzt den Mauszeiger zu einer Hand
            >
              <Card.Img variant="top" src="..." alt="Advanced Bronze Paket" />
              <Card.Body>
                <Card.Title>Bronze</Card.Title>
                <p>5€ / Monat</p>
                <Card.Text>Empfohlen für eine große Welt mit vielen Freunden und Plugins und einigen Mods</Card.Text>
                <ul className="list-group">
                  <li className="list-group-item">4 GB Ram</li>
                  <li className="list-group-item">100 GB Speicher</li>
                  <li className="list-group-item">Intel CPU</li>
                </ul>
              </Card.Body>
            </Card>
          </div>
          {/* Advanced Silber Paket */}
          <div className="col-sm-4">
            <Card 
              as="a" 
              onClick={() => handlePackageSelect('Advanced Silber')} 
              className="text-decoration-none" 
              style={{ cursor: 'pointer' }}  // Setzt den Mauszeiger zu einer Hand
            >
              <Card.Img variant="top" src="..." alt="Advanced Silber Paket" />
              <Card.Body>
                <Card.Title>Silber</Card.Title>
                <p>5€ / Monat</p>
                <Card.Text>Empfohlen für eine große Welt mit einigen Freunden und Plugins und kleine Modpacks</Card.Text>
                <ul className="list-group">
                  <li className="list-group-item">5 GB Ram</li>
                  <li className="list-group-item">125 GB Speicher</li>
                  <li className="list-group-item">Intel CPU</li>
                </ul>
              </Card.Body>
            </Card>
          </div>
          {/* Advanced Gold Paket */}
          <div className="col-sm-4">
            <Card 
              as="a" 
              onClick={() => handlePackageSelect('Advanced Gold')} 
              className="text-decoration-none" 
              style={{ cursor: 'pointer' }}  // Setzt den Mauszeiger zu einer Hand
            >
              <Card.Img variant="top" src="..." alt="Advanced Gold Paket" />
              <Card.Body>
                <Card.Title>Gold</Card.Title>
                <p>5€ / Monat</p>
                <Card.Text>Empfohlen für eine große Welt mit vielen Freunden und Plugins und Modpacks</Card.Text>
                <ul className="list-group">
                  <li className="list-group-item">6 GB Ram</li>
                  <li className="list-group-item">150 GB Speicher</li>
                  <li className="list-group-item">Intel CPU</li>
                </ul>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      <h2 className="text-start mt-5">Individuell:</h2>
      <Card className="container mb-5">
        <Card.Body>
          <Card.Title>Individuell</Card.Title>
          <Card.Text>Passe deinen Server ganz nach deinem Bedarf an</Card.Text>
          <Form>
            <Form.Group controlId="formRam">
              <Form.Label>Arbeitsspeicher (GB): {ram}</Form.Label>
              <RangeSlider
                value={ram}
                onChange={(e) => setRam(parseInt(e.target.value))}
                min={1}
                max={16}
                tooltip='auto'
              />
            </Form.Group>
            <Form.Group controlId="formStorage" className="mt-3">
              <Form.Label>Speicher (GB): {storage}</Form.Label>
              <RangeSlider
                value={storage}
                onChange={(e) => setStorage(parseInt(e.target.value))}
                min={25}
                max={500}
                step={25}
                tooltip='auto'
              />
            </Form.Group>
            <Form.Group controlId="formCpu" className="mt-3">
              <Form.Label>CPU-Kerne: {cpu}</Form.Label>
              <RangeSlider
                value={cpu}
                onChange={(e) => setCpu(parseInt(e.target.value))}
                min={1}
                max={8}
                tooltip='auto'
              />
            </Form.Group>
          </Form>
          <h5 className="mt-3">Gesamtkosten: {totalCost} € / Monat</h5>
        </Card.Body>
      </Card>

      <h1 className="fw-bold my-4">Features:</h1>
      <Table bordered>
        <thead>
            <tr>
            <th></th>
            <th scope="col">Basic</th>
            <th scope="col">Advanced</th>
            <th scope="col">Individuell</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td>Processor
                <p className="text-secondary">CPU model und Taktrate</p>
            </td>
            <td>AMD Epyc 7351P, 2.4 GHz / 2.9 GHz</td>
            <td>AMD Epyc 7351P, 2.4 GHz / 2.9 GHz</td>
            <td>AMD Epyc 7351P, 2.4 GHz / 2.9 GHz</td>
            </tr>
            <tr>
            <td>Anti DDoS
                <p className="text-secondary">Schutz vor DDoS attacken</p>
            </td>
            <td>L3/L4 Schutz</td>
            <td>L3/L4 Schutz</td>
            <td>L3/L4 Schutz</td>
            </tr>
        </tbody>
      </Table>

    </Container>
  );
};

export default Preise;
