import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Table } from 'react-bootstrap';

const Home = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const isDebugging = process.env.NODE_ENV === 'development';
  const BackendAddress = isDebugging ? 'http://localhost:3001' : 'https://backendtest.dein-game-server.de'; 

  useEffect(() => {
    axios.get(`${BackendAddress}/api/data`)
      .then(response => {
        if (Array.isArray(response.data)) {
          setData(response.data);
        } else {
          setError('The data received is not in an expected format.');
        }
      })
      .catch(error => {
        setError('There was an error fetching the data!');
      });
  }, []);

  return (
    <Container>
      <h1>My Data</h1>
      {error ? <p>{error}</p> : null}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map(item => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.name}</td>
              </tr>
            ))
          ) : (
            !error && <tr><td colSpan="2">No data available</td></tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default Home;
