import React, { useState, useEffect  } from 'react';
import { Route, Routes, Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Navbar, Nav, Container } from 'react-bootstrap';
import Home from './components/Home';
import Register from './components/Register';
import Login from './components/Login';
import Servers from './components/Servers';
import PrivateRoute from './components/PrivateRoute';
import Support from './components/Support';
import Preise from './components/Preise';
import Erstellen from './components/Erstellen';
import Balance from './components/Balance';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  
  const navigate = useNavigate(); // Importiere und verwende useNavigate

  useEffect(() => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    const storedUsername = localStorage.getItem('username') || sessionStorage.getItem('username');
    const tokenExpiry = localStorage.getItem('tokenExpiry');
  
    if (token) {
      if (tokenExpiry && new Date().getTime() > tokenExpiry) {
        // Token ist abgelaufen, also alle Daten löschen
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('tokenExpiry');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('username');
      } else {
        setIsAuthenticated(true);
        setUsername(storedUsername || '');
      }
    }
  }, []);

  const handleLogin = (user) => {
    setIsAuthenticated(true);
    setUsername(user.username);
    localStorage.setItem('username', user.username);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    setIsAuthenticated(false);
    setUsername('');
    navigate('/login');
  };

  return (
    <div className="App">
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="/">Dein-Mc-Server</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">Startseite</Nav.Link>
              <Nav.Link as={Link} to="/Preise">Preise</Nav.Link>
              <Nav.Link as={Link} to="/support">Support</Nav.Link>
              {isAuthenticated && (
                <>
                  <Nav.Link as={Link} to="/servers">Servers</Nav.Link>
                  <Nav.Link as={Link} to="/erstellen">Erstellen</Nav.Link>
                </>
              )}
            </Nav>
            <Nav className="ms-auto">
              {isAuthenticated ? (
                <>
                  <Navbar.Text className="me-3">
                    Hallo, {username}
                  </Navbar.Text>
                  <Nav.Link as={Link} to="/balance">Balance</Nav.Link>
                  <Nav.Link onClick={handleLogout}>Abmelden</Nav.Link>
                </>
              ) : (
                <>
                  <Nav.Link as={Link} to="/register">Registrieren</Nav.Link>
                  <Nav.Link as={Link} to="/login">Login</Nav.Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/preise" element={<Preise />} />
        <Route path="/support" element={<Support />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/servers" element={<PrivateRoute><Servers /></PrivateRoute>} />
        <Route path="/erstellen" element={<PrivateRoute><Erstellen /></PrivateRoute>} />
        <Route path="/balance" element={<PrivateRoute><Balance /></PrivateRoute>} />
      </Routes>

      <footer className="black-top-boarder footer text-muted p-2 mt-5">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 g-2 justify-content-center justify-content-evenly">
          <div className="text-wrap border-1 box-shadow bg-light-dark rounded-3 p-4 col" style={{ width: '20rem' }}>
            Vielen Dank für den Besuch dieser Webseite. Wir hoffen, dass wir dich mit unseren Produkten überzeugen konnten. Solltest du Fragen haben kannst du uns gerne kontaktieren. Die Kontaktmöglichkeiten findest du rechts neben diesem Text.
          </div>
          <div className="bg-light-dark rounded-3 p-4 col" style={{ width: '20rem' }}>
            <p className="fs-4 mb-2">Kontakt:</p>
            <p className="my-1">Mail: <a href="mailto:support@dein-game-server.de">support@dein-game-server.de</a></p>
            <p className="mt-1">WhatsApp: <a href="https://api.whatsapp.com/send?phone=4915236955157" target="_blank">+4915236955157</a></p>
            <div>
              <p className="fs-5 mb-0">Adresse:</p>
              <p>Florian Poschmann <br />Hohler Weg 6 <br />30827 Garbsen</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
