import React from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
const Support = () => {
  return (
    <Container>
      <h1 className="text-center fw-bold">Support</h1>
      <div className="row row-cols-1 row-cols-md-2 g-4 my-5 justify-content-center">
        <div>
          <div className="card">
            <div className="card-header fw-bold fs-4 text-green">
              Allgemeine Fragen
            </div>
            <div className="accordion accordion-flush" id="accordionflushfaq1">
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne1">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsalgfraeins1"
                    aria-expanded="false"
                    aria-controls="collapsalgfraeins1"
                  >
                    Wie kann ich mich anmelden?
                  </button>
                </h2>
                <div
                  id="collapsalgfraeins1"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne1"
                  data-bs-parent="#accordionflushfaq1"
                >
                  <div className="accordion-body text-black">
                    Anmelden kannst du dich auf unserer Webseite ganz einfach
                    oben rechts in der Ecke, dort stehen dir dann verschiedene
                    Anmeldeoptionen zur Verfügung.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="card">
            <div className="card-header fw-bold fs-4 text-green">
              Server Fragen
            </div>
            <div className="accordion accordion-flush" id="accordionflushfaq2">
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne2">
                  <button
                    className="accordion-button collapsed border-bottom"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseserverfrageneins"
                    aria-expanded="false"
                    aria-controls="collapseserverfrageneins"
                  >
                    Wie kann ich Mods hinzufügen?
                  </button>
                </h2>
                <div
                  id="collapseserverfrageneins"
                  className="accordion-collapse collapse border-bottom"
                  aria-labelledby="flush-headingOne2"
                  data-bs-parent="#accordionflushfaq2"
                >
                  <div className="accordion-body text-black">
                    Im Datein Explorer die zugehörige apk hochladen.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo2">
                  <button
                    className="accordion-button collapsed border-bottom"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseserverfragenzwei"
                    aria-expanded="false"
                    aria-controls="collapseserverfragenzwei"
                  >
                    Was kann ich gegen Lags unternehmen?
                  </button>
                </h2>
                <div
                  id="collapseserverfragenzwei"
                  className="accordion-collapse collapse border-bottom"
                  aria-labelledby="flush-headingTwo2"
                  data-bs-parent="#accordionflushfaq2"
                >
                  <div className="accordion-body text-black">
                    Zuerst solltest du versuchen deinen Server neu zu starten,
                    sollte dies nicht den gewünschten Effekt erzeugen gibt es
                    noch die Möglichkeit die Lags mit speziellen Plugins zu
                    verringern. Sollte all dies nicht helfen gibt es auch noch
                    die Möglichkeit mehr Arbeitsspeicher zu kaufen.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree2">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseserverfragendrei"
                    aria-expanded="false"
                    aria-controls="collapseserverfragendrei"
                  >
                    Wie kann ich ein Backup wiederherstellen?
                  </button>
                </h2>
                <div
                  id="collapseserverfragendrei"
                  className="accordion-collapse collapse border-bottom"
                  aria-labelledby="flush-headingThree2"
                  data-bs-parent="#accordionflushfaq2"
                >
                  <div className="accordion-body text-black">
                    Gehe dafür einfach in dein panel auf Backup und klicke auf
                    wieder herstellen.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="card light">
            <div className="card-header fw-bold fs-4 text-green">
              Account Fragen
            </div>
            <div className="accordion accordion-flush" id="accordionflushfaq3">
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne3">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#colllapseacceins"
                    aria-expanded="false"
                    aria-controls="colllapseacceins"
                  >
                    Wie ändere ich meine Mail Adresse?
                  </button>
                </h2>
                <div
                  id="colllapseacceins"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne3"
                  data-bs-parent="#accordionflushfaq3"
                >
                  <div className="accordion-body text-black">
                    Die Mail Adresse kannst du unter deinem Account ändern
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div className="my-2 row justify-content-around">
          <a
            className="btn-success btn col-4 col btn-lg p-3 fs-3"
            href="https://wa.me/message/3DLN7SQ6R674P1"
            role="button"
          >
            <i className="fa-brands fa-whatsapp"></i> WhatsApp
          </a>
          <a
            className="btn-info btn col-4 col btn-lg p-3 fs-3"
            href="mailto:support@dein-game-server.de"
            role="button"
          >
            <i className="fa-regular fa-envelope"></i> Mail
          </a>
        </div>
      </div>
    </Container>
  );
};

export default Support;
